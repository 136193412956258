import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Injectable()
export class ExportExcelService {

    public exportAsExcelFile(json: any[], excelFileName: string, excelSheetName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        worksheet['!cols'] = this.fitToColumn(json[0]);
        const workbook: XLSX.WorkBook = { Sheets: { 'Produtos': worksheet }, SheetNames: ['Produtos'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private fitToColumn(colunas): XLSX.ColInfo[]  {
        let listaColunas = [];
        Object.keys(colunas).map(key => listaColunas.push({width: key.length < 10? 15: key.length}));
        return listaColunas;
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        const initialDate = moment(new Date());
        const initialDateFormated = initialDate.format('YYYYMMDD_hhmmss');
        FileSaver.saveAs(data, fileName + "_"+initialDateFormated + '.xlsx');
    }
}