import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    //const token = localStorage.getItem('token');
    let reqClone = {};
    // if (token) {
    //   reqClone = {
    //     headers: req.headers.set('Token', token),
    //   };
    // }
    return next.handle(req.clone(reqClone));
  }
}
