import { ContainerAppComponent } from "./components/container-app/container-app.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "../app/views/login/login.component";
import { AuthGuard } from "./auth/auth.guard";
import { VisualizaFolderComponent } from "./views/visualiza-folder/visualiza-folder.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login/:logout",
    component: LoginComponent,
    data: {
      title: "Login",
    },
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login",
    },
  },
  {
    path: "pdf",
    loadChildren: () =>
      import("src/app/views/visualiza-folder/visualiza-folder.module").then(
        (m) => m.VisualizaFolderModule
      ),
    data: {
      title: "Visualizador da Campanha",
    },
  },
  {
    path: "",
    component: ContainerAppComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Desdobra",
    },
    children: [
      {
        path: "solicitar-alteracao-senha",
        loadChildren: () =>
          import("src/app/views/alterar-senha/alterar-senha.module").then(
            (m) => m.AlterarSenhaModule
          ),
      },
      {
        path: "home",
        loadChildren: () =>
          import("src/app/views/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "usuario",
        loadChildren: () =>
          import("src/app/views/usuario/usuario.module").then(
            (m) => m.UsuarioModule
          ),
      },
      {
        path: "empresa",
        loadChildren: () =>
          import("src/app/views/empresa/empresa.module").then(
            (m) => m.EmpresaModule
          ),
      },
      {
        path: "empresa-form",
        loadChildren: () =>
          import("src/app/views/empresa-form/empresa-form.module").then(
            (m) => m.EmpresaFormModule
          ),
      },
      {
        path: "fabricante",
        loadChildren: () =>
          import("src/app/views/fabricante/fabricante.module").then(
            (m) => m.FabricanteModule
          ),
      },
      {
        path: "fabricante-form",
        loadChildren: () =>
          import("src/app/views/fabricante-form/fabricante-form.module").then(
            (m) => m.FabricanteFormModule
          ),
      },
      {
        path: "produto",
        loadChildren: () =>
          import("src/app/views/produto/produto.module").then(
            (m) => m.ProdutoModule
          ),
      },
      {
        path: "produto-form",
        loadChildren: () =>
          import("src/app/views/produto-form/produto-form.module").then(
            (m) => m.ProdutoFormModule
          ),
      },
      {
        path: "template",
        loadChildren: () =>
          import("src/app/views/template/template.module").then(
            (m) => m.TemplateModule
          ),
      },
      {
        path: "template-form",
        loadChildren: () =>
          import("src/app/views/template-form/template-form.module").then(
            (m) => m.TemplateFormModule
          ),
      },
      {
        path: "folder",
        loadChildren: () =>
          import("src/app/views/folder/folder.module").then(
            (m) => m.FolderModule
          ),
      },
      {
        path: "folder-form",
        loadChildren: () =>
          import("src/app/views/folder-form/folder-form.module").then(
            (m) => m.FolderFormModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
