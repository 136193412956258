import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario.model';

@Injectable()
export class LocalStorageService {
  public get<T>(key: string, parse: boolean = true): T | any {
    if (parse) {
      return JSON.parse(localStorage.getItem(key)) as T;
    }

    return localStorage.getItem(key);
  }

  public saveItem(key: string, data: any): void {
    if (typeof data === 'string') {
      return localStorage.setItem(key, data);
    }

    return localStorage.setItem(key, JSON.stringify(data));
  }
  
  public removeItem(key: string): void {
    return localStorage.removeItem(key);
  }

  public getUsuarioLogado(): Usuario {
    return JSON.parse(localStorage.getItem('usuarioLogado')) as Usuario;
  }
}
