import { LocalStorageService } from './../../services/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { navItems } from './navbarConfig';
import { Usuario } from 'src/app/models/usuario.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './container-app.component.html',
  styleUrls: ['./container-app.component.scss']
})
export class ContainerAppComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = [];
  public usuarioLogado: Usuario = new Usuario();
  constructor(
    private localStorageService: LocalStorageService, 
    public router: Router,
    private toastService: ToastrService) { }

  ngOnInit(): void {
    this.usuarioLogado = this.localStorageService.getUsuarioLogado();
    if(this.usuarioLogado && this.usuarioLogado.perfil)
        this.navItems = this.usuarioLogado.admin ? navItems : navItems.filter( item => this.filterNav(item, this.usuarioLogado));
    else{
      this.toastService.warning("Usuário não encontrado ou sem perfil");
      this.router.navigate(['/login/false']);
    }
  }

  selecionarEmpresa(empresaId: string):void
  { 
    this.usuarioLogado.empresaId = empresaId;
    this.usuarioLogado.empresaDesc = (this.usuarioLogado.empresas.find(({id})=> id === empresaId)).nome;
    this.localStorageService.saveItem('usuarioLogado', this.usuarioLogado);
    this.toastService.success('Empresa trocada');
  }

  empresaSelecionada(empresaId: string): boolean{
    return this.usuarioLogado.empresaId === empresaId;
  }

  filterNav(item, usuarioLogado){
    if(item.profiles.includes('all') || item.profiles.includes(usuarioLogado.perfil))
    {
      if(item.children){
        item.children = item.children.filter(item => this.filterNav(item, usuarioLogado));
        return item.children.length > 0;
      }
      return true
    }
    return false;
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
