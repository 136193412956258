import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, take, distinctUntilChanged, catchError } from 'rxjs/operators';

import { BaseService } from './base.service';
import { AppConfig } from '../config/app.config';
import { Sessao } from '../models/sessao.model';

@Injectable()
export class SessaoService extends BaseService {
  constructor(http: HttpClient) {
    super('sessao', http);
  }

  public logoff(token: string):  Observable<Sessao> {

      const sessao = {token};

      return this.httpClient
      .post<Sessao>(
        `${AppConfig.settings.urlApi}/${this.address}`,
        sessao,
        this.httpOptions
      )
      .pipe(
        debounceTime(700),
        take(1),
        distinctUntilChanged(),
        catchError(this.handleError)
      )
  }
}
