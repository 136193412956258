import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { AppConfig } from '../config/app.config';
import { Empresa } from '../models/empresa.model';
import { Pagina } from '../models/pagina.model';

@Injectable()
export class EmpresaService extends BaseService {
  constructor(http: HttpClient) {
    super(`${AppConfig.settings.urlApi}/Empresa`, http);
  }

  public listarTodos(): Observable<Empresa[]> {
    return super.listarTodosBase();
  }

  public listarTodosAtivos(empresaId: string): Observable<Empresa[]> {
    return super.customListarTodos(`AllActive?empresaId=${empresaId}`);
  }

  public listarTodosAtivosPagina(empresaId: string, pagina: number): Observable<Pagina<Empresa>> {
    return super.customListarPagina(`GetPageActive?empresaId=${empresaId}&pagina=${pagina}`);
  }

  public obter(id: string): Observable<Empresa> {
    return super.obterBase<Empresa>(id);
  }

  public salvar(data: Empresa, logo: FormData): Observable<Empresa> {
    return super.salvarArquivo<Empresa>(`Inserir?empresaId=${data.empresaId}&codigo=${data.codigo}&nome=${data.nome}&cnpj=${data.cnpj}`, logo);
  }

  public atualizar(data: Empresa, logo: FormData): Observable<Empresa> {
    return super.atualizarArquivo<Empresa>(`Atualizar?id=${data.id}&codigo=${data.codigo}&nome=${data.nome}&cnpj=${data.cnpj}`, logo);
  }

  public inativar(id: string, inativar: boolean) : Observable<Empresa> {
    return super.customPut(`Inativar?id=${id}&inativar=${inativar}`);
  }

  public deletar(id:string) : any {
    return super.deleteBase(id);
  }
}
