export class DefaultModel {
  public id?: string;
  public empresaId: string;
  public active?: boolean;
  public createdDate?: string;
  public updatedDate?: string;
  public createdBy: string;
  public updatedBy?: string;

  public toModel?(entity: any): DefaultModel {
    if (entity) {
      this.id = entity.id;
      this.empresaId = entity.empresaId;
      this.active = entity.active;
      this.createdDate = entity.createdDate;
      this.updatedDate = entity.updatedDate;
      this.createdBy = entity.createdBy;
      this.updatedBy = entity.updatedBy;
    }

    return this;
  }
}

export class DefaultErrorModel {
  public message: string
  public status: number

  public toModel?(entity: any): DefaultErrorModel {
    if (entity) {
      this.message = entity.message;
      this.status = entity.status;
    }

    return this;
  }
}