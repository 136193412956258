import {
  CommonModule,
  registerLocaleData,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import ptBr from "@angular/common/locales/pt";
import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule,
} from "@coreui/angular";

import { AlertModule } from "ngx-bootstrap/alert";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ToastrModule, ToastrService } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { ContainerAppComponent } from "./components/container-app/container-app.component";
import { LoginComponent } from "./views/login/login.component";

import { AppRoutingModule } from "./app-routing.module";

import { UsuarioService } from "./services/usuario.service";
import { LocalStorageService } from "./services/local-storage.service";

import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { ResponseInterceptor } from "./interceptors/response.interceptor";
import { BlobErrorHttpInterceptor } from "./interceptors/blob-error-http.interceptor";

import { initializer } from "./config/app-init";
import { AppConfig } from "./config/app.config";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthGuard } from "./auth/auth.guard";
import { SessaoService } from "./services/sessao.service";

import { NgxMaskModule, IConfig } from "ngx-mask";
import {
  CurrencyMaskConfig,
  CurrencyMaskModule,
  CURRENCY_MASK_CONFIG,
} from "ng2-currency-mask";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { UtilsService } from "./services/utils.service";
import { ExportExcelService } from "./services/export-excel.service";
import { EmpresaService } from "./services/empresa.service";
import { TemplateService } from "./services/template.service";
import { ProdutoService } from "./services/produto.service";
import { FabricanteService } from "./services/fabricante.service";
import { FolderService } from "./services/folder.service";
import { FolderProdutoService } from "./services/folderProduto.service";
import { AlertService } from "./services/alert/alert.service";
// import { ClipboardModule } from "ngx-clipboard";
registerLocaleData(ptBr);
const APP_CONTAINERS = [ContainerAppComponent];

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  prefix: "",
  align: "left",
  allowNegative: false,
  thousands: ".",
  decimal: ",",
  precision: 2,
  suffix: "",
};

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    AppRoutingModule,
    AlertModule.forRoot(),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot(),
    NgbModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    NgMultiSelectDropDownModule,
    // ClipboardModule

  ],
  declarations: [AppComponent, ...APP_CONTAINERS, LoginComponent],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-BR" },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [AppConfig],
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig,
    },
    AppConfig,
    ToastrService,
    LocalStorageService,
    UsuarioService,
    EmpresaService,
    FabricanteService,
    ProdutoService,
    TemplateService,
    FolderService,
    FolderProdutoService,
    AuthGuard,
    SessaoService,
    ExportExcelService,
    UtilsService,
    AlertService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
