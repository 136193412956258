/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Usuario } from '../models/usuario.model';
import { LocalStorageService } from '../services/local-storage.service';

import { UsuarioService } from '../services/usuario.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private localStorage: LocalStorageService,
    private router: Router
  ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // if (this.usuarioService.usuarioEstaAutenticado()) {
        //   const user = this.localStorage.get<Usuario>('user') as Usuario;
        //   //const token = this.localStorage.get('token', false);

        //   if (user/* && token*/) {
        //     if (!route.data.adminOption) {
        //       return true;
        //     }
        //     if (user.admin) {
        //       return true;
        //     }
        //   }
        // }

        const user = this.localStorage.get<Usuario>('usuarioLogado') as Usuario;
        //const token = this.localStorage.get('token', false);

        if (user/* && token*/) {
          if (!route.data.adminOption) {
            return true;
          }
          if (user.admin) {
            return true;
          }
        }
        
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
}

