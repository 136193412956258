import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { AppConfig } from '../config/app.config';
import { FolderProduto } from '../models/folderProduto.model';
import { Pagina } from '../models/pagina.model';

@Injectable()
export class FolderProdutoService extends BaseService {
  constructor(http: HttpClient) {
    super(`${AppConfig.settings.urlApi}/FolderProduto`, http);
  }

  public listarTodosFolder(folderId: string): Observable<Pagina<FolderProduto>> {
    return super.customListarPagina(`All?folderId=${folderId}`);
  }

  public obter(id: string): Observable<FolderProduto> {
    return super.obterBase<FolderProduto>(id);
  }

  public salvar(data: FolderProduto): Observable<FolderProduto> {
    return super.salvarBase<FolderProduto>(data);
  }

  public atualizar(data: FolderProduto): Observable<FolderProduto> {
    return super.atualizarBase<FolderProduto>(data.id, data);
  }

  public inativar(id: string, inativar: boolean): Observable<FolderProduto> {
    return super.customPut(`Inativar?id=${id}&inativar=${inativar}`);
  }

  public deletar(id: string): any {
    return super.deleteBase(id);
  }
  public deletarAll(id) {
    return super.customPost(`DeletarAll`, id);
  }
  public importarPlanilha(empresaId: string, folderId: string, arquivo: FormData): Observable<any> {
    return super.salvarArquivo<FormData>(`ImportarPlanilhaProdutosFolder?empresaId=${empresaId}&folderId=${folderId}`, arquivo);
  }
}
