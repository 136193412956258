import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { AppConfig } from '../config/app.config';
import { Produto } from '../models/produto.model';
import { Pagina } from '../models/pagina.model';

@Injectable()
export class ProdutoService extends BaseService {
  constructor(http: HttpClient) {
    super(`${AppConfig.settings.urlApi}/Produto`, http);
  }

  public listarTodos(): Observable<Produto[]> {
    return super.listarTodosBase();
  }

  public listarTodosAtivos(empresaId: string): Observable<Produto[]> {
    return super.customListarTodos(`AllActive?empresaId=${empresaId}`);
  }

  public listarTodosAtivosPagina(empresaId: string, pagina: number): Observable<Pagina<Produto>> {
    return super.customListarPagina(`GetPageActive?empresaId=${empresaId}&pagina=${pagina}`);
  }

  public PesquisarItem(empresaId: string, termoPesquisa : string): Observable<Pagina<Produto>> {
    return super.customListarPagina(`pesquisarItems?empresaId=${empresaId}&termoPesquisa=${termoPesquisa}`);
  }

  public listarTodosFabricanteFolderId(fabricanteId: string, folderId: string, pagina : number): Observable<Produto[]> {
    return super.customListarTodos(`AllFabricanteFolderId?fabricanteId=${fabricanteId}&folderId=${folderId}&pagina=${pagina}`);
  }

  public PesquisarItemFabricante(fabricanteId: string, folderId: string, termoPesquisa : string): Observable<Produto[]> {
    return super.customListarTodos(`pesquisarItemsFabricante?fabricanteId=${fabricanteId}&folderId=${folderId}&termoPesquisa=${termoPesquisa}`);
  }

  public obter(id: string): Observable<Produto> {
    return super.obterBase<Produto>(id);
  }

  public salvar(data: Produto, logo: FormData): Observable<Produto> {
    return super.salvarArquivo<Produto>(`Inserir?empresaId=${data.empresaId}&codigo=${data.codigo}&descricao=${data.descricao}&fabricanteId=${data.fabricanteId}&linkExterno=${data.linkExterno}`, logo);
  }

  public atualizar(data: Produto, logo: FormData): Observable<Produto> {
    return super.atualizarArquivo<Produto>(`Atualizar?id=${data.id}&codigo=${data.codigo}&descricao=${data.descricao}&fabricanteId=${data.fabricanteId}&linkExterno=${data.linkExterno}`, logo);
  }

  public inativar(id: string, inativar: boolean): Observable<Produto> {
    return super.customPut(`Inativar?id=${id}&inativar=${inativar}`);
  }

  public deletar(id: string): any {
    return super.deleteBase(id);
  }

  public importarPlanilha(empresaId: string, arquivo: FormData): Observable<any> {
    return super.salvarArquivo<FormData>(`ImportarPlanilhaProdutos?empresaId=${empresaId}`, arquivo);
  }

  public atualizacaoImagem(arquivo: FormData): Observable<any> {

    return super.salvarArquivo<FormData>(`UpdateImageProdutos`, arquivo);
  }
  public deletarAll(id) {
    return super.customPost(`DeletarAll`, id);
  }

  // private serialize(obj, prefix) {
  //   var str = [],
  //     p;
  //   for (p in obj) {
  //     if (obj.hasOwnProperty(p)) {
  //       var k = prefix ? prefix + "[" + p + "]" : p,
  //         v = obj[p];
  //       str.push((v !== null && typeof v === "object") ?
  //         this.serialize(v, k) :
  //         encodeURIComponent(k) + "=" + encodeURIComponent(v));
  //     }
  //   }
  //   return str.join("&");
  // }
}
