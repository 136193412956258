import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { AppConfig } from '../config/app.config';
import { EnumStr } from 'src/app/models/enum.model';

@Injectable()
export class UtilsService extends BaseService {
  constructor(http: HttpClient) {
    super(`${AppConfig.settings.urlApi}/Utils`, http);
  }

  public buscarPerfis(): Observable<EnumStr[]> {
    return super.customListarTodos('BuscarPerfis');
  }

  public buscarModelosTemplate(): Observable<EnumStr[]> {
    return super.customListarTodos('BuscarModelosTemplate');
  }
}