import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { AppConfig } from '../config/app.config';
import { catchError, take } from 'rxjs/operators';
import { Usuario } from '../models/usuario.model';
import { Pagina } from '../models/pagina.model';

@Injectable()
export class UsuarioService extends BaseService {
  constructor(http: HttpClient) {
    super(`${AppConfig.settings.urlApi}/Usuario`, http);
  }

  public login(login: string, senha: string): Observable<Usuario> {
    var funcionarioLogin  = {
      login: login,
      senha: senha
    };

    return this.httpClient.post<Usuario>(
      `${this.address}/login`, funcionarioLogin, this.httpOptions)
      .pipe(catchError(this.handleError), take(1));
  }

  public listarTodos(): Observable<Usuario[]> {
    return super.listarTodosBase();
  }

  public listarTodosAtivos(empresaId: string): Observable<Usuario[]> {
    return super.customListarTodos(`AllActive?empresaId=${empresaId}`);
  }

  public listarTodosAtivosPagina(empresaId: string, pagina: number): Observable<Pagina<Usuario>> {
    return super.customListarPagina(`GetPageActive?empresaId=${empresaId}&pagina=${pagina}`);
  }

  public listarTodosAdmin(): Observable<Usuario[]> {
    return super.customListarTodos('GetAllAdmin');
  }

  public listarTodosAdminPagina(empresaId: string, pagina: number): Observable<Pagina<Usuario>> {
    return super.customListarPagina(`GetAllAdminPage?empresaId=${empresaId}&pagina=${pagina}`);
  }

  public salvar(data: Usuario): Observable<Usuario> {
    return super.salvarBase<Usuario>(data);
  }

  public atualizar(data: Usuario): Observable<Usuario> {
    return super.atualizarBase<Usuario>(data.id, data);
  }

  public deletar(id:string) : any {
    return super.deleteBase(id);
  }

  public resetarSenha(id: string) : Observable<Usuario> {
    return super.customPut(`resetarSenha?usuarioId=${id}`);
  }

  public alterarSenha(login: string, senha: string, novaSenha: string) : Observable<any> {
    const usuarioAlterarSenha = {
      login: login,
      senhaAtual: senha,
      senhaNova: novaSenha,
    };

    return this.httpClient.post<Usuario>(`${this.address}/alterarSenha`, usuarioAlterarSenha, this.httpOptions);
  }

  public logoff(): Observable<any> {
    return super.customPost<any>('logout');
  }
}
