<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="coluna-esquerda"></div>
      <div class="coluna-direita">
        <div class="login-container">
          <h4 style="color: rgb(0, 0, 0); text-align: center;">Desdobra</h4>
          <form (submit)="logar($event)" style="display: flex; flex-direction: column; justify-items: center; align-items: center;">
            <div class="input-group" style="padding-top: 10px">
              <div class="input-group-prepend">
                <span class="input-group-text input-span"><i class="fa fa-user"></i></span>
              </div>
              <input
                type="login"
                name="login"
                class="form-control"
                placeholder="Usuario ou e-mail"
                required
                [(ngModel)]="login"
              />
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text input-span"><i class="fa fa-lock"></i></span>
              </div>
              <input
                type="password"
                name="password"
                class="form-control"
                placeholder="Senha"
                [(ngModel)]="senha"
                required
              />
            </div>
            <div class="botao">
              <button [disabled]="running" type="submit" class="btn btn-primary" style="width: 100%">
                <div *ngIf="running">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                  <span class="visually-hidden">Logando...</span>
                </div>
                <div *ngIf="!running">Logar</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</div>
