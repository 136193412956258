<div style="height: 10px;">
  <app-header [navbarBrandRouterLink]="['/home']" [fixed]="true"
    [navbarBrandFull]="{src: 'assets/img/logo.png', width:200, height: 100, alt: 'Desdobra'}" [sidebarToggler]="'lg'"
    [asideMenuToggler]="false" [mobileAsideMenuToggler]="false" style="height:65px; position: fixed;">
    <div *ngIf="router.url !== '/home' || usuarioLogado.empresas.length <= 0"
      style="color: #530169; font-weight: bold;font-size: 16px; padding-left: 10px;">
      {{(usuarioLogado && usuarioLogado.empresaId) ? usuarioLogado.empresaDesc : ''}}
    </div>
    <div *ngIf="router.url === '/home' && usuarioLogado.empresas.length > 0"
      style="display: inline-flex; gap: 15px; padding-left: 10px;">
      <div style="align-self: center; color: #530169; font-weight: bold;font-size: 16px;">Empresa:</div>
      <select class="form-control" style="height: 30px" (change)="selecionarEmpresa($event.target.value)">
        <option [value]="empresa.id" *ngFor="let empresa of usuarioLogado.empresas"
          [selected]="empresaSelecionada(empresa.id)">{{empresa.nome}}</option>
      </select>
    </div>
    <ul class="nav navbar-nav ml-auto mr-4">
      <li class="nav-item dropdown" dropdown placement="bottom right">
        <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
          dropdownToggle (click)="false" name="user">
          Olá, {{(usuarioLogado && usuarioLogado.nome) ? usuarioLogado.nome : 'anônimo'}}
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
          <a class="dropdown-item" routerLink="/solicitar-alteracao-senha" name="change-password">Alterar senha</a>
          <a class="dropdown-item" routerLink="/login/true" name="logout">Sair</a>
        </div>
      </li>
    </ul>
  </app-header>
</div>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" class="menu-lateral"
    (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
  </app-sidebar>
  <main class="main">
    <cui-breadcrumb>
    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
