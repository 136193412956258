import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { AppConfig } from '../config/app.config';
import { Template } from '../models/template.model';
import { Pagina } from '../models/pagina.model';

@Injectable()
export class TemplateService extends BaseService {
  constructor(http: HttpClient) {
    super(`${AppConfig.settings.urlApi}/Template`, http);
  }

  public listarTodos(): Observable<Template[]> {
    return super.listarTodosBase();
  }

  public listarTodosAtivos(empresaId: string): Observable<Template[]> {
    return super.customListarTodos(`AllActive?empresaId=${empresaId}`);
  }

  public listarTodosAtivosPagina(empresaId: string, pagina: number): Observable<Pagina<Template>> {
    return super.customListarPagina(`GetPageActive?empresaId=${empresaId}&pagina=${pagina}`);
  }

  public PesquisarItem(empresaId: string, termoPesquisa : string): Observable<Pagina<Template>> {
    return super.customListarPagina(`pesquisarItems?empresaId=${empresaId}&termoPesquisa=${termoPesquisa}`);
  }

  public obter(id: string): Observable<Template> {
    return super.obterBase<Template>(id);
  }

  public salvar(data: Template, images: FormData): Observable<Template> {
    return super.salvarArquivo<Template>(`Inserir?empresaId=${data.empresaId}&nome=${data.nome}&modeloTemplate=${data.modeloTemplate}`, images);
  }

  public atualizar(data: Template, images: FormData): Observable<Template> {
    return super.atualizarArquivo<Template>(`Atualizar?id=${data.id}&nome=${data.nome}&modeloTemplate=${data.modeloTemplate}`, images);
  }

  public inativar(id: string, inativar: boolean) : Observable<Template> {
    return super.customPut(`Inativar?id=${id}&inativar=${inativar}`);
  }

  public deletar(id:string) : any {
    return super.deleteBase(id);
  }
}
