import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LocalStorageService } from '../../services/local-storage.service'
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public login: string = '';
  public senha: string = '';
  public loginError: string;
  public running: boolean;

  constructor(
    private usuarioService: UsuarioService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private toastService: ToastrService,
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.params.logout) {
      this.usuarioService.logoff()
      .subscribe(() => {
        this.loginError = null;
        this.router.navigate(['/login']);
        this.localStorage.removeItem('usuarioLogado');
      },
      (err: HttpErrorResponse) => {
        this.toastService.error("Erro ao realizar logoff");
      });
    }
  }

  logar(event: Event): void {
    event.preventDefault();
    this.running = true;

    this.usuarioService.login(this.login, this.senha).subscribe(
      res => {
        this.localStorage.saveItem('usuarioLogado', res);
        if(res && res.perfil){
            this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/home']);
          }); 
        }
        else
          this.toastService.error("Usuário sem perfil");

      },
      err => {
        this.showError(err);
      }).add(() =>{
        this.running = false;
      });
  }

  showError(err): void {
    this.toastService.error(err);
  }
}
