import { ICustomNavData } from './ICustomNavData';

export const navItems: ICustomNavData[] = [
  {
    name: 'Home',
    url: '/home',
    icon: 'fa fa-home',
    adminOption: false,
    profiles: ['all']
  },
  // {
  //   name: 'Cadastro',
  //   url: '/cadastros',
  //   icon: 'fa fa-adjust',
  //   adminOption: false,
  //   profiles: ['Geral', 'Cadastros'],
  //   children: [
  //   ]
  // },
  {
    name: 'Usuários',
    url: '/usuario',
    icon: 'fa fa-user-circle',
    adminOption: true,
    profiles: [],
  },
  {
    name: 'Empresas',
    url: '/empresa',
    icon: 'fa fa-building',
    adminOption: true,
    profiles: [],
  },
  {
    name: 'Fabricantes',
    url: '/fabricante',
    icon: 'fa fa-industry',
    adminOption: false,
    profiles: ['Geral', 'Cadastros'],
  },
  {
    name: 'Produtos',
    url: '/produto',
    icon: 'fa fa-shopping-bag',
    adminOption: false,
    profiles: ['Geral', 'Cadastros'],
  },
  {
    name: 'Modelos',
    url: '/template',
    icon: 'fa fa-file-image-o',
    adminOption: false,
    profiles: ['Geral', 'Cadastros'],
  },
  {
    name: 'Prontos',
    url: '/folder',
    icon: 'fa fa-folder',
    adminOption: false,
    profiles: ['Geral', 'Cadastros'],
  },
];
