import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { AppConfig } from '../config/app.config';
import { Fabricante } from '../models/fabricante.model';
import { Pagina } from '../models/pagina.model';

@Injectable()
export class FabricanteService extends BaseService {
  constructor(http: HttpClient) {
    super(`${AppConfig.settings.urlApi}/Fabricante`, http);
  }

  public listarTodos(): Observable<Fabricante[]> {
    return super.listarTodosBase();
  }

  public listarTodosAtivos(empresaId: string): Observable<Fabricante[]> {
    return super.customListarTodos(`AllActive?empresaId=${empresaId}`);
  }

  public listarTodosAtivosPagina(empresaId: string, pagina: number): Observable<Pagina<Fabricante>> {
    return super.customListarPagina(`GetPageActive?empresaId=${empresaId}&pagina=${pagina}`);
  }

  public PesquisarItem(empresaId: string, termoPesquisa : string): Observable<Pagina<Fabricante>> {
    return super.customListarPagina(`pesquisarItems?empresaId=${empresaId}&termoPesquisa=${termoPesquisa}`);
  }

  public obter(id: string): Observable<Fabricante> {
    return super.obterBase<Fabricante>(id);
  }

  public salvar(data: Fabricante, logo: FormData): Observable<Fabricante> {
    return super.salvarArquivo<Fabricante>(`Inserir?empresaId=${data.empresaId}&nome=${data.nome}`, logo);
  }

  public atualizar(data: Fabricante, logo: FormData): Observable<Fabricante> {
    return super.atualizarArquivo<Fabricante>(`Atualizar?id=${data.id}&nome=${data.nome}`, logo);
  }

  public inativar(id: string, inativar: boolean) : Observable<Fabricante> {
    return super.customPut(`Inativar?id=${id}&inativar=${inativar}`);
  }

  public deletar(id:string) : any {
    return super.deleteBase(id);
  }
}
