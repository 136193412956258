/* eslint-disable @typescript-eslint/no-empty-function */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessaoService } from '../services/sessao.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(private router: Router,private sessaoService: SessaoService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401:
            case 403:
              this.sessaoService.logoff("");
              this.router.navigate(['login']);
            default: return;
          }
        }
      }));
  }
}
