import { DefaultModel } from "./default-model.model";
import { EmpresaUsuario } from "./empresa.model";

export class Usuario extends DefaultModel {
  public empresaDesc: string;
  public nome: string;
  public email: string;
  public login: string;
  public senha?: string;
  public cpf: string;
  public multiEmpresas: boolean;
  public primeiroAcesso: boolean;
  public perfil: string;
  public perfilDesc: string;
  public admin: boolean;
  public empresas: EmpresaUsuario[];

  toModel(entity): Usuario {
    if (entity) {
      super.toModel(entity);
      this.empresaDesc = entity.empresaDesc;
      this.nome = entity.nome;
      this.email = entity.email;
      this.login = entity.login;
      this.senha = entity.senha;
      this.cpf = entity.cpf;
      this.multiEmpresas = entity.multiEmpresas;
      this.primeiroAcesso = entity.primeiroAcesso;
      this.perfil = entity.perfil;
      this.perfilDesc = entity.perfilDesc;
      this.admin = entity.admin;
      this.empresas = entity.empresas;
    }

    return this;
  }

}
