import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { AppConfig } from '../config/app.config';
import { Folder } from '../models/folder.model';
import { Pagina } from '../models/pagina.model';

@Injectable()
export class FolderService extends BaseService {
  constructor(http: HttpClient) {
    super(`${AppConfig.settings.urlApi}/Folder`, http);
  }

  public listarTodos(): Observable<Folder[]> {
    return super.listarTodosBase();
  }

  public listarTodosAtivos(empresaId: string): Observable<Folder[]> {
    return super.customListarTodos(`AllActive?empresaId=${empresaId}`);
  }

  public listarTodosAtivosPagina(empresaId: string, pagina: number): Observable<Pagina<Folder>> {
    return super.customListarPagina(`GetPageActive?empresaId=${empresaId}&pagina=${pagina}`);
  }

  public PesquisarItem(empresaId: string, termoPesquisa : string): Observable<Pagina<Folder>> {
    return super.customListarPagina(`pesquisarItems?empresaId=${empresaId}&termoPesquisa=${termoPesquisa}`);
  }

  public obter(id: string): Observable<Folder> {
    return super.obterBase<Folder>(id);
  }

  public salvar(data: Folder): Observable<Folder> {
    return super.salvarBase<Folder>(data);
  }

  public atualizar(data: Folder): Observable<Folder> {
    return super.atualizarBase<Folder>(data.id, data);
  }

  public inativar(id: string, inativar: boolean): Observable<Folder> {
    return super.customPut(`Inativar?id=${id}&inativar=${inativar}`);
  }

  public baixarFolderPdf(folderId: string): Observable<any> {
    return super.download(`GerarFolderPdf?folderId=${folderId}`);
  }

  public deletar(id: string): any {
    return super.deleteBase(id);
  }

  public GerarFolderPdf(folderId: string): Observable<any> {
    return super.customListarTodos(`GetFolderPdf?folderId=${folderId}`);
  }


}
